import React, { useMemo } from "react";
import { useTextures } from "./hooks";
import * as THREE from "three";

const Foundation = ({
  nodes,
  config,
  selected,
  setSelected,
  materials,
  ...props
}) => {
  const material = useTextures(["vloer.jpg"]);

  const geom = useMemo(() => new THREE.PlaneBufferGeometry(162, 61), []);

  return (
    <>
      <mesh
        name="vloer"
        geometry={geom}
        material={material}
        castShadow
        receiveShadow
        position={[-1.36, -0.85, 1.2]}
        rotation-x={Math.PI * -0.5}
      />

      <group position={[78.08, 7.11, 0.79]} rotation={[0, 1.57, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.muur_1.geometry}
          material={nodes.muur_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.muur_2.geometry}
          material={nodes.muur_2.material}
        />
      </group>
    </>
  );
};

export default Foundation;
