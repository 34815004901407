import React, { Suspense, useMemo } from "react";
import { useGLTF } from "@react-three/drei";

const Fence = (props) => {
  // GLTF
  const gltf = useGLTF("./fence.glb");
  const copiedScene = useMemo(() => gltf.scene.clone(), [gltf.scene]);

  return (
    <Suspense fallback={null}>
      <primitive {...props} object={copiedScene} />
    </Suspense>
  );
};

useGLTF.preload("./fence.glb");

export default Fence;
