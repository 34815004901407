import React, { useState, useEffect, useRef } from "react";
import { useLoader, useThree } from "@react-three/fiber";
import * as THREE from "three";

import { useSound } from "./hooks";

const Sound = ({ url }) => {
  const sound = useRef();
  const { camera } = useThree();
  const [listener] = useState(() => new THREE.AudioListener());
  const buffer = useLoader(THREE.AudioLoader, url);

  const { refClicked } = useSound();

  useEffect(() => {
    sound.current.setBuffer(buffer);
    sound.current.setRefDistance(1);
    sound.current.setLoop(true);
    sound.current.setVolume(0.05);

    if (!sound.current.listener) camera.add(listener);

    return () => camera.remove(listener);
  }, [buffer, camera, listener]);

  React.useEffect(() => {
    if (refClicked) {
      sound.current.play();
    } else {
      sound.current.pause();
    }
  }, [refClicked]);

  return <positionalAudio ref={sound} args={[listener]} />;
};

export default Sound;
