import React, { useState, useRef, useEffect, Suspense } from "react";
import "./App.css";

import axios from "axios";

import { useSnapshot } from "valtio";

import state, {
  getTextForObjectName,
  getDownloadsForObjectName,
} from "./globalState";

import { isMobile, isTablet } from "react-device-detect";

import "bootstrap/dist/css/bootstrap.min.css";

import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";

import { extend, useFrame, useThree, Canvas } from "@react-three/fiber";
import { EffectComposer, Outline } from "@react-three/postprocessing";
import { isIOS, isSafari } from "react-device-detect";

// import kiwiLogo from "./kiwi-logo.svg";
import Accordion from "react-bootstrap/Accordion";

import {
  Environment,
  useContextBridge,
  Preload,
  Stats,
  Effects,
} from "@react-three/drei";
import Model from "./Model";
import { OrbitControls } from "./utils/OrbitControls";
import Loader from "./Loader";
import Typist from "react-text-typist";

import { context, soundContext, useSound } from "./hooks";

extend({ OrbitControls });

export const Controls = ({ onAttach = () => {}, cameraProps, ...props }) => {
  const { gl, camera, set } = useThree(({ camera, gl, set }) => {
    gl.setPixelRatio(window.devicePixelRatio);

    return { camera, gl, set };
  });
  const controls = useRef();
  const cameraRef = useRef();

  useFrame((state, delta) => {
    if (controls.current) {
      controls.current.update();
      controls.current.updateTween();
    }
  });

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const containerWidth = 300;
    camera.controls = controls.current;
    camera.setViewOffset(
      width,
      height,
      -(containerWidth / 2),
      0,
      width,
      height
    );
    onAttach();
  }, [camera, onAttach]);

  useEffect(() => {
    set({ camera: cameraRef.current });
  }, [set]);

  return (
    <>
      <perspectiveCamera ref={cameraRef} {...cameraProps} />
      <orbitControls ref={controls} args={[camera, gl.domElement]} {...props} />
    </>
  );
};

function Scene(props) {
  const [hovered, setHovered] = useState(null);
  const selected = hovered ? [hovered] : [];

  return (
    <>
      <context.Provider value={{ hovered, setHovered }}>
        <Controls
          enableDamping
          rotateSpeed={0.3}
          dampingFactor={0.1}
          cameraProps={{
            position: [-170, 3, 20],
            near: 0.01,
            far: 1000,
            fov: 50,
          }}
        />

        <Model />

        <Environment preset="sunset" background />
        <Stats />

        <EffectComposer autoClear={false}>
          <Outline
            blur
            edgeStrength={10}
            selection={selected}
            visibleEdgeColor="white"
            hiddenEdgeColor="#190a05"
            pulseSpeed={0.2}
            width={1000}
          />
        </EffectComposer>
      </context.Provider>
    </>
  );
}

function App() {
  const snap = useSnapshot(state);
  const [disabledInstructions, disableInstructions] = useState(false);
  const [fadeOut, setFadeout] = useState(false);
  const [canvasLoaded, setCanvasLoaded] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);

  const { refClicked, setRefClicked, transitioning } = useSound();

  const ContextBridge = useContextBridge(soundContext);

  const Muted = () => (
    <i className="fas fa-volume-mute" style={{ fontSize: 30 }}></i>
  );
  const Unmuted = () => (
    <i className="fas fa-volume-up" style={{ fontSize: 30 }}></i>
  );

  const testDownload = async (url, downloadName) => {
    await axios
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        const mimeType = response.data.type.split("/").pop();

        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: response.data.type });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;

        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", `${downloadName}.${mimeType}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    window.addEventListener(
      "enable-instructions",
      (e) => {
        if (disabledInstructions && !canvasLoaded) {
          return;
        }
        setFadeout(true);

        setTimeout(() => {
          disableInstructions(true);
          setFadeout(false);
        }, 1500);
      },
      true
    );
  }, [canvasLoaded, disabledInstructions]);

  const classNames = () => {
    if (fadeOut && !disabledInstructions) {
      return "visible fadeout";
    } else if (disabledInstructions && !fadeOut) {
      return "d-none";
    } else {
      return "visible";
    }
  };

  const transitionClassNames = () => {
    if (!transitioning.fading) {
      return "hidden";
    } else if (transitioning.fading && transitioning.mode === "fade-in") {
      return "visible";
    } else {
      return "visible fadeout";
    }
  };

  return (
    <>
      <div
        id="app"
        className="App d-flex align-items-center justify-content-center vh-100"
      >
        <div
          onClick={() =>
            !disabledInstructions &&
            window.dispatchEvent(new Event("enable-instructions"))
          }
          onPointerOut={() =>
            !disabledInstructions &&
            window.dispatchEvent(new Event("enable-instructions"))
          }
          id="canvas-container"
          style={{ height: "100vh", width: "100vw" }}
        >
          <div
            className={`bg-dark transition text-white ${transitionClassNames()}`}
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 99999,
            }}
          >
            <h2
              style={{
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <Typist sentences={["Navigeren naar binnen..."]} loop={false} />
            </h2>
          </div>

          <div className="instructions-container">
            <div className={`instructions look ${classNames()}`}>
              <div className="inner">
                <div className="hand"></div>

                <div className="copy">Klik en houd vast om rond te kijken</div>
              </div>
            </div>
          </div>

          <div
            className="panel-container state-about in-app"
            data-ref="panel_container"
          >
            <div className={`panel ${showFAQ ? "visible" : ""}`}>
              <div className="about">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="title mb-0">Doorbraak Museum</h2>
                  <div
                    onClick={() => setShowFAQ(false)}
                    className="close"
                  ></div>
                </div>

                <div className="copy">
                  <p>
                    Welkom in het Doorbraak Museum. Bij de expo 'Actieprogramma
                    Dak- en Thuisloze jongeren' in opdracht van het Ministerie
                    van Volksgezondheid, Welzijn en Sport.
                  </p>
                  <p>
                    <strong className="d-block">Waar ben ik?</strong> Een 3D
                    museum vol concrete, grensverleggende oplossingen om dak- en
                    thuisloosheid onder jongeren aan te pakken. De veertien
                    schilderijen inspireren niet alleen; ze laten zien dat het
                    ook écht kan. Ontwikkelt door veertien pilotgemeenten in
                    samenwerking met het Instituut voor Publieke Waarden.
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Hoe navigeer ik binnen de app?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Binnen de app heb je verschillende mogelijkheden om te
                          navigeren. Zo kun je klikken op objecten waar een
                          witte rand omheen zit. Zodra je klikt navigeer je
                          automatisch naar dit object toe. Om terug te navigeren
                          naar de plek waar je vandaan komt, klik je nogmaals op
                          hetzelfde object.
                        </p>
                        <p>
                          Met je linkermuisknop ingedrukt kun je roteren van
                          links naar rechts en ook van boven naar beneden. Tot
                          slot kun je ook zoomen. Dit doe je met het scrollwiel
                          op je muis.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Help de app werkt niet goed
                      </Accordion.Header>
                      <Accordion.Body>
                        Als de app niet naar behoren werkt kun je het probleem
                        melden via{" "}
                        <a
                          href="mailto:support@publiekewaarden.nl"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          support@publiekewaarden.nl
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Wordt mijn apparaat ondersteund?
                      </Accordion.Header>
                      <Accordion.Body>
                        Momenteel ondersteunen de volgende browsers:{" "}
                        <strong>Edge (96+), Chrome (9+), Firefox (4+)</strong>.
                        Volledige ondersteuning voor Safari komt later.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Kan ik de app downloaden?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Nee de app kan niet gedownload worden zoals een
                          Appstore of Google Playstore app. Wel kun je de app op
                          je beginscherm vast zetten. Zo krijg je wel de
                          ervaring van een gedownloaden app.{" "}
                        </p>

                        <p>
                          Meer informatie hoe dit werkt vind je voor{" "}
                          <a
                            href="https://www.brigitvarenkamp.nl/een-iphone-website-shortcut/"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {" "}
                            iPhone
                          </a>{" "}
                          hier en voor Android{" "}
                          <a
                            href="https://www.seniorweb.nl/tip/site-op-startscherm-zetten"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            hier
                          </a>
                          .
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <span className="mt-3 badge bg-dark">
                    Versie {process.env.REACT_APP_VERSION}
                  </span>
                </div>

                {/* <footer>
                  <div className="credits">
                    <p className="text-center">
                      Ook geïnteresseerd om 3D in te zetten voor jouw bedrijf?{" "}
                      <a href="mailto:info@dutchkiwidesign.nl">
                        <strong>Get in touch</strong>
                      </a>
                      .
                    </p>

                    <img
                      alt="Dutch Kiwi Design logo"
                      class="me-1"
                      height="50px"
                      src="https://apk-station-goirle-api-production.s3.eu-central-1.amazonaws.com/dutch_kiwi_logo_copyright_2a34900974.svg"
                      width="50px"
                    />
                    <a
                      className="text-success text-decoration-none"
                      href="https://www.dutchkiwidesign.nl/realisaties"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      Created with love by{" "}
                      <strong class="text-dark text-decoration-underline">
                        Dutch Kiwi Design
                      </strong>
                    </a>
                  </div>
                </footer> */}
              </div>
            </div>
          </div>

          <div
            className={`about-button ${showFAQ ? "" : "visible"}`}
            onClick={() => setShowFAQ(true)}
          ></div>

          <Canvas
            gl={{ logarithmicDepthBuffer: true }}
            onCreated={() => setCanvasLoaded(true)}
            colorManagement
            performance={{ min: 0.7, max: 1 }}
            dpr={1}
            mode="concurrent"
          >
            <ContextBridge>
              <Suspense fallback={<Loader />}>
                <Scene />
              </Suspense>
            </ContextBridge>
            <Preload all />
          </Canvas>

          {state.popupMode && (
            <>
              <ToastContainer
                className="p-3"
                position="bottom-center"
                style={{ zIndex: 2 }}
              >
                <Toast>
                  <Toast.Header closeButton={false}>
                    <strong className="me-auto">
                      {getTextForObjectName(snap.activeObject).text}
                    </strong>
                  </Toast.Header>
                  <Toast.Body>
                    <div className="row">
                      {getDownloadsForObjectName(snap.activeObject).map(
                        (download, key) => {
                          return (
                            <div
                              onClick={() =>
                                testDownload(download.url, download.name)
                              }
                              key={key}
                              className="col-6 "
                            >
                              <button className="btn btn-primary w-100">
                                Download {key + 1}
                              </button>
                            </div>
                          );
                        }
                      )}
                    </div>

                    {snap.activeObject === "schilderij-7" && (
                      <>
                        <hr />
                        <div className="mt-2 row text-start">
                          <div className="col-12">
                            Bekijk het schilderij aan de andere kant van de muur
                            via de onderstaande knop.
                          </div>

                          <div className="col-12 mt-2">
                            <button
                              onClick={() =>
                                window.dispatchEvent(new Event("turn"))
                              }
                              className="btn btn-dark w-100"
                            >
                              Draaien
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    {getTextForObjectName(snap.activeObject).showTypewriter && (
                      <Typist
                        sentences={[
                          "Welkom bij het digitale museum van de doorbraak methode",
                          "Wij heten jou van harte welkom in dit museum.",
                          "Neem een kijkje rond en bekijk de vele projecten die wij verwezelijkt hebben.",
                        ]}
                        loop={false}
                        startDelay={500}
                      />
                    )}
                  </Toast.Body>
                </Toast>
              </ToastContainer>
            </>
          )}
          {!isIOS && !isSafari && (
            <button
              onClick={() => setRefClicked(!refClicked)}
              className="position-absolute btn-circle"
              style={{ zIndex: 2, bottom: 25, right: 25 }}
            >
              {refClicked ? <Unmuted /> : <Muted />}
            </button>
          )}
        </div>
      </div>
      <div id="warning-message">
        <div className="row  g-0">
          <div className="d-flex justify-content-center col-12 ">
            <img
              alt="roteer apparaat gif"
              width="50%"
              src={"./roteer-apparaat.gif"}
            />
          </div>

          <div className="col-12 text-center text-white">
            <h2>Draai uw apparaat a.u.b</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
