import React, { useState } from "react";
import useHover from "./hooks";
import { useCursor } from "@react-three/drei";

const Objects = ({
  nodes,
  config,
  selected,
  setSelected,
  materials,
  customRef,
  beginRef,
  tutorialMode,
  ...props
}) => {
  const [hovered, setHover] = useState(false);

  useCursor(hovered);

  return (
    <>
      <mesh
        visible={tutorialMode}
        name="hulp-pijl"
        castShadow
        receiveShadow
        geometry={nodes["hulp-pijl"].geometry}
        material={materials["Default OBJ.002"]}
        position={[-64.57, 3.82, -19.96]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
      />
      <group position={[76.66, 5.05, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bord_1.geometry}
          material={materials["Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bord_2.geometry}
          material={materials["Material.003"]}
        />
      </group>
      <mesh
        {...useHover({
          theta: -2.5,
          phi: 1.5,
          radius: 7,
          objectName: "flipover",
          limitTheta: false,
          needsRefPosition: true,
        })}
        castShadow
        receiveShadow
        geometry={nodes.flipover003.geometry}
        material={materials.flipover}
        position={[-64.32, 2.94, 7.28]}
        scale={[0.06, 0.07, 0.07]}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.shelf1.geometry}
        material={nodes.shelf1.material}
        position={[76.45, 7.02, 11.73]}
      />

      <group position={[0, -1.03, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lade_1.geometry}
          material={nodes.lade_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lade_2.geometry}
          material={materials.Masonite}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lade_3.geometry}
          material={materials.Handles}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lade_4.geometry}
          material={materials.Wood}
        />
      </group>
      <group position={[-64.42, 1.33, -0.12]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bureau_1.geometry}
          material={nodes.bureau_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bureau_2.geometry}
          material={materials.Rough_white}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bureau_3.geometry}
          material={materials.Metal}
        />
      </group>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.shelf2.geometry}
        material={nodes.shelf2.material}
        position={[76.44, 4.37, 11.73]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.flipover002.geometry}
        material={materials["Material #39"]}
        position={[-64.32, 2.94, 7.28]}
        scale={[0.06, 0.07, 0.07]}
      />
      <mesh
        ref={beginRef}
        castShadow
        receiveShadow
        geometry={nodes["bureau-tekst"].geometry}
        material={materials["Material.024"]}
        position={[-65.55, 0.87, -0.13]}
        rotation={[Math.PI / 2, 0, 1.56]}
        scale={[0.47, 0.47, 0.47]}
      />
      <group position={[76.5, 0.26, -12.13]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube008.geometry}
          material={materials["Material.021"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube008_1.geometry}
          material={materials["Material.022"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.flipover.geometry}
        material={materials["Material #36"]}
        position={[-64.32, 2.94, 7.28]}
        scale={[0.06, 0.07, 0.07]}
      />

      <mesh
        {...useHover({
          theta: -1.5,
          phi: -1,
          radius: 10,
          objectName: "logos",
          limitTheta: false,
          needsRefPosition: true,
        })}
        castShadow
        receiveShadow
        geometry={nodes.logos.geometry}
        material={materials["Material.006"]}
        position={[-69.15, -0.82, -0.71]}
        scale={[2.86, 2.86, 4.83]}
      />

      {/* Flyers */}
      <group position={[-64.46, 2.46, -1.83]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-1_1"].geometry}
          material={materials["Material.018"]}
        />
        <mesh
          {...useHover({
            theta: -1.2,
            phi: 1.5,
            radius: 3,
            objectName: "flyer-1",
            limitTheta: false,
            needsRefPosition: true,
          })}
          castShadow
          receiveShadow
          geometry={nodes["flyer-1_2"].geometry}
          material={materials["Material.019"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-1_3"].geometry}
          material={materials["None.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-1_4"].geometry}
          material={materials["None_NONE.004"]}
        />
      </group>
      <group position={[-64.46, 2.46, -0.1]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-2_1"].geometry}
          material={materials["Material.079"]}
        />
        <mesh
          {...useHover({
            theta: -1.2,
            phi: 1.5,
            radius: 3,
            objectName: "flyer-2",
            limitTheta: false,
            needsRefPosition: true,
          })}
          castShadow
          receiveShadow
          geometry={nodes["flyer-2_2"].geometry}
          material={materials["Material.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-2_3"].geometry}
          material={materials["None.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-2_4"].geometry}
          material={materials["None_NONE.002"]}
        />
      </group>
      <group position={[-64.3, 2.2, 1.49]}>
        <mesh
          {...useHover({
            theta: -1.2,
            phi: 1.5,
            radius: 3,
            objectName: "flyer-3",
            limitTheta: false,
            needsRefPosition: true,
          })}
          castShadow
          receiveShadow
          geometry={nodes["flyer-3_1"].geometry}
          material={materials["Material.010"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-3_2"].geometry}
          material={materials["None.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-3_3"].geometry}
          material={materials["Material.012"]}
        />
      </group>
      <group position={[-64.3, 2.2, 3.05]}>
        <mesh
          {...useHover({
            theta: -1.2,
            phi: 1.5,
            radius: 3,
            objectName: "flyer-4",
            isLeft: false,
            limitTheta: false,
            needsRefPosition: true,
          })}
          castShadow
          receiveShadow
          geometry={nodes["flyer-4_1"].geometry}
          material={materials["Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-4_2"].geometry}
          material={materials["None.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-4_3"].geometry}
          material={materials["None_NONE.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["flyer-4_4"].geometry}
          material={materials["Material.023"]}
        />
      </group>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.voormuur.geometry}
        material={nodes.voormuur.material}
        position={[-82.39, 7.11, 0.82]}
        rotation={[0, 1.57, 0]}
        scale={[110.35, 8, 0.22]}
      />
      <group position={[-72.58, 8.6, 30.82]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.ramen001_1.geometry}
          material={materials["Plastic_Handle_material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.ramen001_2.geometry}
          material={materials["Window_material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.ramen001_3.geometry}
          material={materials["Glass_material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.ramen001_4.geometry}
          material={materials["Sill_material.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.flipover001.geometry}
        material={materials["Material #30"]}
        position={[-64.32, 2.94, 7.28]}
        scale={[0.06, 0.07, 0.07]}
      />
      <mesh
        {...useHover({
          objectName: "banner",
          pathPosition: [-64.57, -0.78, -19.96],
          y: 10,
          radius: 5,
        })}
        castShadow
        receiveShadow
        geometry={nodes.banner.geometry}
        material={materials["Material.005"]}
        position={[-64.57, 7.94, -28.07]}
      />
      <mesh
        onClick={() => window.open("https://www.youtube.nl", "_blank")}
        castShadow
        receiveShadow
        geometry={nodes.video.geometry}
        material={materials["Material.017"]}
        position={[-64.65, 4.69, -27.63]}
      />
      <mesh
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}
        onClick={() =>
          window.open(
            "https://www.rijksoverheid.nl/documenten/rapporten/2019/03/14/actieprogramma-dak-en-thuisloze-jongeren-2019-2021",
            "_blank"
          )
        }
        castShadow
        receiveShadow
        geometry={nodes["link-hotspot-fliperover"].geometry}
        material={nodes["link-hotspot-fliperover"].material}
        position={[-64.46, 4.91, 7.5]}
        rotation={[0, -0.49, -1.57]}
        scale={[0.15, 1.36, 1.31]}
      />

      <mesh
        onClick={() => {
          var anchor = window.document.createElement("a");
          anchor.href = "mailto:support@publiekewaarden.nl";
          anchor.target = "_blank";
          anchor.click();
        }}
        castShadow
        receiveShadow
        geometry={nodes["hotspot-link-banner"].geometry}
        material={nodes["hotspot-link-banner"].material}
        position={[-64.73, 3.18, -27.59]}
        rotation={[-1.57, Math.PI / 2, 0]}
      />
      <mesh
        name="Hand"
        castShadow
        receiveShadow
        geometry={nodes.Hand.geometry}
        material={nodes.Hand.material}
        position={[-31.85, 5.71, -0.42]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
      />
    </>
  );
};

export default Objects;
