import React from "react";
import useHover from "./hooks";
import { useTextures } from "./hooks";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import gsap from "gsap";
import { useSound } from "./hooks";
import globalState from "./globalState";

const Painting = ({
  position,
  frontMaterial,
  sideMaterials,
  geom,
  isLeft,
  texturePath,
  count,
  pathPosition,
  ...rest
}) => {
  const material = useTextures([texturePath]);
  const { camera } = useThree();

  const { setNextPainting } = useSound();

  const { ref, ...restHover } = useHover({
    objectName: texturePath.split(".")[0],
    isLeft: isLeft,
    pathPosition,
  });

  const triggerTurn = React.useCallback(() => {
    const min = new THREE.Vector3(60.66, 5.99, 30.095);
    const max = new THREE.Vector3(70.66, 10.99, 30.345);

    var aabb = new THREE.Box3(min, max);
    var center = aabb.getCenter(new THREE.Vector3());
    var size = aabb.getSize(new THREE.Vector3());

    camera.controls.setAzimuthAngle(-Infinity, Infinity);
    camera.controls.enabled = false;

    globalState.activeObject = "schilderij-8";
    globalState.popupMode = true;

    gsap
      .timeline()
      .to(camera.controls.target, {
        duration: 1,
        x: center.x,
        y: center.y,
        z: center.z, // maybe adding even more offset depending on your model
        onUpdate: function () {
          camera.controls.update();
        },
        onComplete: function () {
          camera.controls.enabled = true;
          camera.controls.update();
        },
      })
      .to(camera.position, {
        duration: 1,
        x: center.x - size.x,
        y: center.y + size.y,
        z: center.z - size.z * 6, // maybe adding even more offset depending on your model
        onUpdate: function () {
          camera.controls.update();
        },
        onComplete: function () {
          camera.controls.rotate((50 * Math.PI) / 180);
          camera.controls.enabled = true;
          // firstUpdate.current = false;
          camera.controls.update();
          setNextPainting(false);
        },
      });
  }, [camera.controls, camera.position, setNextPainting]);

  React.useEffect(() => {
    let listener = null;

    if (!listener && count === 7) {
      listener = window.addEventListener("turn", (e) => triggerTurn(e), true);
    }

    return () =>
      window.removeEventListener("turn", (e) => triggerTurn(e), true);
  }, [camera, count, triggerTurn]);

  return (
    <mesh
      ref={ref}
      geometry={geom}
      material={[
        sideMaterials,
        sideMaterials,
        sideMaterials,
        material,
        sideMaterials,
        sideMaterials,
      ]}
      castShadow
      receiveShadow
      rotation-x={Math.PI * -0.5}
      rotation-z={isLeft ? 0 : Math.PI * 1}
      position={position}
      {...rest}
      {...restHover}
    />
  );
};
export default React.memo(Painting);
