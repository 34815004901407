import React, {
  useMemo,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { useThree, useLoader } from "@react-three/fiber";
import * as THREE from "three";
import * as TWEEN from "tween";
import globalState from "./globalState";
import { isMobile, isTablet } from "react-device-detect";
import { useCursor } from "@react-three/drei";

const context = React.createContext();
const soundContext = React.createContext();

window.camToSave = {};

function useHover(config = {}) {
  const ref = useRef();
  const [tweenRunning, setTweenRunning] = useState(false);

  // Camera
  const { camera } = useThree();

  const { hovered, setHovered } = useContext(context);
  const { paintingMode, setPaintingMode } = useSound();

  const onPointerOver = useCallback(() => setHovered(ref), [setHovered]);
  const onPointerOut = useCallback((e) => setHovered(null), [setHovered]);

  useCursor(hovered);

  function onClick(e) {
    if (tweenRunning || !ref.current || ref.current === paintingMode.meshname) {
      return;
    }

    const xyz = config.pathPosition;
    let position = new THREE.Vector3();

    if (config.needsRefPosition) {
      position.setFromMatrixPosition(ref.current.matrixWorld);
    } else {
      position.set(xyz[0], config.y || 7, xyz[2]);
    }

    camera.updateMatrixWorld();
    camera.controls.enabled = false;
    camera.controls.update();

    setTweenRunning(true);
    setPaintingMode({ meshname: ref.current });

    camera.controls.cameraTo(
      position,
      config.theta || 0.1,
      config.phi || 1.5, // phi - null=default
      config.radius || 2, // radius
      2000,
      config.objectName || "object-a",
      null,
      () => {
        setTweenRunning(false);
      },
      config.isLeft,
      config.limitTheta
    );
  }

  function triggerClick(e) {
    (isMobile || isTablet) && ref.current && onClick(e);
  }

  return {
    ref,
    onPointerOver,
    onPointerOut,
    onClick,
    onPointerDown: triggerClick,
  };
}

function SoundProvider(props) {
  const [refClicked, setRefClicked] = useState(false);
  const [nextPainting, setNextPainting] = useState(false);
  const [paintingMode, setPaintingMode] = useState({});
  const [transitioning, setTransitioning] = useState({
    fading: false,
    mode: "",
  });

  const value = React.useMemo(
    () => ({
      refClicked,
      setRefClicked,
      nextPainting,
      setNextPainting,
      transitioning,
      setTransitioning,
      paintingMode,
      setPaintingMode,
    }),
    [
      refClicked,
      setRefClicked,
      nextPainting,
      setNextPainting,
      transitioning,
      setTransitioning,
      paintingMode,
      setPaintingMode,
    ]
  );

  return <soundContext.Provider value={value} {...props} />;
}

function useSound() {
  const context = React.useContext(soundContext);
  if (context === undefined) {
    throw new Error(`useSound must be used within a SoundProvider`);
  }
  return context;
}

const useTextures = (urls) => {
  const texture = useLoader(TextureLoader, urls);
  const material = useMemo(
    () =>
      new THREE.MeshStandardMaterial({
        map: texture[0],
        aoMap: texture[1],
        displacementMap: texture[2],
        emissiveMap: texture[3],
        normalMap: texture[4],
        roughnessMap: texture[5],
        toneMapped: false,
      }),
    [texture]
  );

  texture.forEach((t) => {
    // Necessary to get correct colors with sRGB outputEncoding!
    t.encoding = THREE.sRGBEncoding;
  });

  return material;
};

export {
  useHover as default,
  context,
  soundContext,
  useSound,
  SoundProvider,
  useTextures,
};
