import React, { useEffect, Suspense } from "react";

import { useGLTF, useAnimations } from "@react-three/drei";

const MuseumKeeper = (props) => {
  const gltf = useGLTF("./police-man.glb");

  // // Extract animation actions
  const { ref, actions, names } = useAnimations(gltf.animations);

  // Change animation when the index changes
  useEffect(() => {
    // Reset and fade in animation after an index has been changed
    actions["Idle"].play();
    // In the clean-up phase, fade it out
    return () => actions[names[6]];
  }, [actions, names]);

  useEffect(() => {
    gltf.scene.traverse(function (obj) {
      obj.frustumCulled = false;
    });
  }, [gltf]);

  return (
    <Suspense fallback={null}>
      <primitive ref={ref} {...props} object={gltf.scene} scale={0.018} />
    </Suspense>
  );
};

useGLTF.preload("./police-man.glb");

export default MuseumKeeper;
