import React from "react";
import { Html, useProgress } from "@react-three/drei";
import ProgressBar from "react-bootstrap/ProgressBar";

function Loader() {
  const { progress } = useProgress();

  const generateLabel = () => {
    const labels = [
      {
        min: 0,
        max: 25,
        label: "3D model laden 📸",
      },
      {
        min: 25,
        max: 50,
        label: "Animaties laden 🎥",
      },
      {
        min: 50,
        max: 75,
        label: "Textures laden 💄",
      },
      {
        min: 75,
        max: 90,
        label: "Scene laden 🖼",
      },
      {
        min: 90,
        max: 100,
        label: "Afronden 🚀",
      },
    ];

    const label = labels.find(
      (obj) => progress >= obj.min && progress <= obj.max
    ).label;

    return label;
  };

  return (
    <Html
      className="loading-wrapper vh-100 vw-100 d-flex align-items-center justify-content-center"
      center
    >
      <div style={{ width: "50%", zIndex: 2 }}>
        <ProgressBar now={progress} />
        <p className="text-white mt-2 fw-bold">{generateLabel()}</p>
      </div>

      <div className="overlay position-absolute" />
    </Html>
  );
}

export default Loader;
