import React, { useMemo, useContext } from "react";
import * as THREE from "three";
import { context } from "./hooks";

import StonePath from "./StonePath";

const GardenPath = ({
  nodes,
  config,
  selected,
  setSelected,
  materials,
  setActive,
  onclick,
  test,
  setTutorialMode,
  ...props
}) => {
  const { setHovered } = useContext(context);

  const positionArray = [
    [-160, -0.7, 20],
    [-155, -0.7, 16],
    [-152, -0.7, 11],
    [-148, -0.7, 4],
    [-140, -0.7, 0],
    [-130, -0.7, -2],
    [-120, -0.7, 0],
    [-113, -0.7, 5],
    [-105, -0.9, 9],
    [-95, -0.9, 14],
  ];

  function getDefaultProps(identifier) {
    return {
      myRef: config.find((obj) => obj.objectName === identifier).ref,
      onClick: (e) => {
        onclick(e, config.find((obj) => obj.objectName === identifier).ref);
      },
      onPointerOver: () => {
        setActive(true);
        setHovered(config.find((obj) => obj.objectName === identifier).ref);
      },
      onPointerUp: (e) => {
        test(e, config.find((obj) => obj.objectName === identifier).ref);
        setActive(false);
        setHovered(null);
      },
    };
  }

  return positionArray.map((position, key) => (
    <StonePath
      name={`tuin-stap-${key + 1}`}
      scale={1}
      position={position}
      key={key}
      {...getDefaultProps(`tuin-stap-${key + 1}`)}
    />
  ));
};

export default GardenPath;
