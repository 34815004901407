import React, { useMemo } from "react";
import Painting from "./Painting";
import * as THREE from "three";
import { Text } from "@react-three/drei";

const Paintings = ({
  nodes,
  config,
  selected,
  setSelected,
  materials,
  ...props
}) => {
  const paintings = [
    {
      textName: "Leefgeld",
      textPosition: [-45.92, 12, -28.07],
      texturePath: "schilderij-1.png",
      position: [-45.92, 7.94, -28.07],
      pathPosition: [-45.92, -0.78, -19.96],
      isLeft: true,
    },
    {
      textName: "Fysiek Briefadres",
      textPosition: [-27.28, 12, -28.07],
      texturePath: "schilderij-2.png",
      position: [-27.28, 7.94, -28.07],
      pathPosition: [-27.28, -0.78, -19.96],

      isLeft: true,
    },
    {
      textName: "Prepaid Pinpas",
      textPosition: [-8.63, 12, -28.07],
      texturePath: "schilderij-3.png",
      position: [-8.63, 7.94, -28.07],
      pathPosition: [-8.63, -0.78, -19.96],

      isLeft: true,
    },

    {
      textName: "Financieel experiment 18-/18+",
      textPosition: [10.02, 12, -28.07],
      texturePath: "schilderij-4.png",
      position: [10.02, 7.94, -28.07],
      pathPosition: [10.02, -0.78, -19.96],

      isLeft: true,
    },
    {
      textName: "Overbruggingsregeling",
      textPosition: [28.66, 12, -28.07],
      texturePath: "schilderij-5.png",
      position: [28.66, 7.94, -28.07],
      pathPosition: [28.66, -0.78, -19.96],

      isLeft: true,
    },
    {
      textName: "Integraal werken met DBM",
      textPosition: [47.31, 12, -28.07],
      texturePath: "schilderij-6.png",
      position: [47.31, 7.94, -28.07],
      pathPosition: [47.31, -0.78, -19.96],

      isLeft: true,
    },

    {
      textName: "Impact DBM",
      textPosition: [65.95, 12, -28.07],
      texturePath: "schilderij-7.png",
      position: [65.95, 7.94, -28.07],
      pathPosition: [65.95, -0.78, -19.96],

      isLeft: true,
    },
    {
      textName: "Doorbraakhypotheek",
      textPosition: [65.66, 12, 30.22],
      texturePath: "schilderij-8.png",
      position: [65.66, 8.49, 30.22],
      pathPosition: [65.66, -0.78, 21.66],

      isLeft: false,
    },

    {
      textName: "Rent for good",
      textPosition: [47.38, 12, 30.22],
      texturePath: "schilderij-9.png",
      position: [47.38, 8.49, 30.22],
      pathPosition: [47.38, -0.78, 21.66],

      isLeft: false,
    },
    {
      textName: "Woonstap 18-",
      textPosition: [29.09, 12, 30.22],
      texturePath: "schilderij-10.png",
      position: [29.09, 8.49, 30.22],
      pathPosition: [29.09, -0.78, 21.66],
      isLeft: false,
    },
    {
      textName: "Woonoplossingen",
      textPosition: [10.81, 12, 30.22],
      texturePath: "schilderij-11.png",
      position: [10.81, 8.49, 30.22],
      pathPosition: [10.81, -0.78, 21.66],

      isLeft: false,
    },
    {
      textName: "Hij weet niet hoe",
      textPosition: [-7.48, 12, 30.22],
      texturePath: "schilderij-12.png",
      position: [-7.48, 8.49, 30.22],
      pathPosition: [-7.48, -0.78, 21.66],

      isLeft: false,
    },
    {
      textName: "Het is netwerken",
      textPosition: [-25.76, 12, 30.22],
      texturePath: "schilderij-13.png",
      position: [-25.76, 8.49, 30.22],
      pathPosition: [-25.76, -0.78, 21.66],

      isLeft: false,
    },
    {
      textName: "Jongerenregisseur",
      textPosition: [-45.09, 12, 30.22],
      texturePath: "schilderij-14.png",
      position: [-45.09, 8.49, 30.22],
      pathPosition: [-45.09, -0.78, 21.66],
      isLeft: false,
    },
  ];

  const geom = useMemo(() => new THREE.BoxBufferGeometry(10, 0.25, 5), []);
  const mat = useMemo(() => new THREE.MeshBasicMaterial(), []);

  return paintings.map((painting, key) => (
    <React.Fragment key={key}>
      {painting.textName && (
        <Text
          rotation-y={painting.isLeft ? 0 : Math.PI * 1}
          position={painting.textPosition}
          color={"#000000"}
          fontSize={1}
          maxWidth={200}
          lineHeight={1}
          letterSpacing={0.02}
          textAlign={"left"}
          anchorX="center"
          anchorY="middle"
        >
          {painting.textName}
        </Text>
      )}

      <Painting
        count={key + 1}
        myRef={painting.ref}
        name={painting.texturePath.split(".")[0]}
        geom={geom}
        position={painting.position}
        isLeft={painting.isLeft}
        texturePath={painting.texturePath}
        sideMaterials={mat}
        pathPosition={painting.pathPosition}
      />
    </React.Fragment>
  ));
};

export default Paintings;
