import React, { Suspense, useRef, useMemo } from "react";
import { useGLTF } from "@react-three/drei";

const StonePath = ({ myRef, ...props }) => {
  // GLTF
  const group = useRef();
  const { scene, materials, nodes } = useGLTF("./stone-path.glb");
  const copiedScene = useMemo(() => scene.clone(), [scene]);

  console.log("copied scene");

  return (
    <Suspense fallback={null}>
      <group scale={1} ref={group} dispose={null}>
        <mesh
          {...props}
          ref={myRef}
          castShadow
          receiveShadow
          geometry={nodes.Rock1.geometry}
          material={materials["Rock1_1.001"]}
        />
      </group>
    </Suspense>
  );
};

useGLTF.preload("./stone-path.glb");

export default StonePath;
