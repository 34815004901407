import React, { useMemo, useContext } from "react";
import * as THREE from "three";
import { context } from "./hooks";

import Step from "./Step";

const Steps = ({
  config,
  setActive,
  onclick,
  test,
  setTutorialMode,
  ...props
}) => {
  const { setHovered } = useContext(context);
  const geom = useMemo(() => new THREE.PlaneBufferGeometry(5, 5), []);
  const mat = useMemo(() => new THREE.MeshBasicMaterial(), []);

  const positionArray = [
    [-64.57, -0.78, -19.96],
    [-45.92, -0.78, -19.96],
    [-27.28, -0.78, -19.96],
    [-8.63, -0.78, -19.96],
    [10.02, -0.78, -19.96],
    [28.66, -0.78, -19.96],
    [47.31, -0.78, -19.96],
    [65.95, -0.78, -19.96],
    [65.66, -0.78, 21.66],
    [47.38, -0.78, 21.66],
    [29.09, -0.78, 21.66],
    [10.81, -0.78, 21.66],
    [-7.48, -0.78, 21.66],
    [-25.76, -0.78, 21.66],
    [-45.09, -0.78, 21.66],
    [-64.57, -0.78, 21.66],
  ];

  function getDefaultProps(identifier) {
    return {
      myRef: config.find((obj) => obj.objectName === identifier).ref,
      onClick: (e) => {
        setTutorialMode(false);
        onclick(e, config.find((obj) => obj.objectName === identifier).ref);
      },
      onPointerOver: () => {
        setActive(true);
        setHovered(config.find((obj) => obj.objectName === identifier).ref);
      },
      // onPointerOut: (e) => {
      //   test(e, config.find((obj) => obj.objectName === identifier).ref);
      //   setActive(false);
      //   setHovered(null);
      // },
      onPointerUp: (e) => {
        console.log("end");
        test(e, config.find((obj) => obj.objectName === identifier).ref);
        setActive(false);
        setHovered(null);
      },
    };
  }

  return positionArray.map((position, key) => (
    <Step
      position={position}
      key={key}
      {...getDefaultProps(`stap-${key + 1}`)}
      geom={geom}
      mat={mat}
    />
  ));
};

export default Steps;
