import React, { useRef, Suspense, useMemo, useContext } from "react";
import gsap from "gsap";
import { useGLTF } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import { context, useSound } from "./hooks";
import { isMobile, isTablet } from "react-device-detect";

const MuseumDoor = ({ config, beginRef, test, setActive, ...props }) => {
  const group = useRef();
  const { setTransitioning } = useSound();
  const { setHovered } = useContext(context);
  // GLTF
  const { nodes, materials } = useGLTF("./museum-deur.glb");

  // Camera
  const camera = useThree((state) => state.camera);

  function animate(identifier) {
    gsap.to(
      config.find((obj) => obj.objectName === identifier).ref.current.rotation,
      {
        duration: 1,
        z: 0.5,
      }
    );

    var aabb = new THREE.Box3().setFromObject(beginRef.current);
    var center = aabb.getCenter(new THREE.Vector3());
    var size = aabb.getSize(new THREE.Vector3());

    setTransitioning({ fading: true, mode: "fade-in" });

    gsap
      .timeline()
      .to(camera.controls.target, {
        delay: 1,
        duration: 1,
        x: center.x,
        y: center.y,
        z: center.z, // maybe adding even more offset depending on your model
        onUpdate: function () {
          camera.controls.update();
        },
        onComplete: function () {
          camera.controls.enabled = true;
          camera.controls.update();
        },
      })
      .to(camera.position, {
        duration: 1,
        x: center.x - size.x,
        y: center.y + size.y * 10,
        z: center.z - size.z * 3, // maybe adding even more offset depending on your model
        onUpdate: function () {
          camera.controls.update();
        },
        onComplete: function () {
          camera.controls.rotate((-50 * Math.PI) / 180);
          camera.controls.enabled = true;
          camera.controls.update();
          setTransitioning({ fading: true, mode: "fade-out" });

          setTimeout(() => setTransitioning({ fading: false, mode: "" }), 500);
        },
      });

    gsap.to(
      config.find((obj) => obj.objectName === identifier).ref.current.rotation,
      {
        delay: 1,
        duration: 1,
        z: 0,
      }
    );
  }

  function getDefaultProps(identifier) {
    return {
      myRef: config.find((obj) => obj.objectName === identifier).ref,
      onClick: (e) => animate(identifier),
      onPointerOver: () => {
        setActive(true);
        setHovered(config.find((obj) => obj.objectName === identifier).ref);
      },
      onPointerUp: (e) => {
        setActive(false);
        setHovered(null);
        (isMobile || isTablet) && animate(identifier);
      },
    };
  }

  return (
    <Suspense fallback={null}>
      <group scale={2} {...props} ref={group} {...props} dispose={null}>
        <mesh
          {...getDefaultProps(`deurklink-1`)}
          ref={getDefaultProps(`deurklink-1`).myRef}
          castShadow
          receiveShadow
          geometry={nodes.Cylinder.geometry}
          material={materials["Material.001"]}
          position={[0.57, 2.02, -0.14]}
          material-color="gold"
          rotation-z={0}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          material={materials["Material_Door_2.jpg"]}
          position={[0, 2.14, -0.14]}
          material-color="#654321Dark"
        />
      </group>
    </Suspense>
  );
};

useGLTF.preload("./museum-deur.glb");

export default MuseumDoor;
