import { proxy } from "valtio";

function getTextForObjectName(name) {
  const strings = {
    intro: {
      text: "Welkom bij het 3D museum out of the box box",
      showTypewriter: true,
    },
    "flyer-1": {
      text: "Financieringsmodel",
      showTypewriter: false,
    },
    "flyer-2": {
      text: "Factsheet en Lessons Learned",
      showTypewriter: false,
    },
    "flyer-3": {
      text: "Lokale verordening Sociaal Domein",
      showTypewriter: false,
    },
    "flyer-4": {
      text: "Morele standaarden voor het Sociaal Domein",
      showTypewriter: false,
    },
    logos: {
      text: "In samenwerking met",
      showTypewriter: false,
    },
    banner: {
      text: "Uitleg Banner",
      showTypewriter: false,
    },
    flipover: {
      text: "Wat is de out of the Box-box?",
      showTypewriter: false,
    },
    "schilderij-1": {
      text: "Leefgeld",
      showTypewriter: false,
    },
    "schilderij-2": {
      text: "Fysiek Briefadres",
      showTypewriter: false,
    },
    "schilderij-3": {
      text: "Prepaid Pinpas",
      showTypewriter: false,
    },
    "schilderij-4": {
      text: "Financieel experiment 18-/18+",
      showTypewriter: false,
    },
    "schilderij-5": {
      text: "Overbruggingsregeling",
      showTypewriter: false,
    },
    "schilderij-6": {
      text: "Integraal werken met DBM",
      showTypewriter: false,
    },
    "schilderij-7": {
      text: "Impact DBM",
      showTypewriter: false,
    },
    "schilderij-8": {
      text: "Doorbraak Hypotheek",
      showTypewriter: false,
    },
    "schilderij-9": {
      text: "Rent for good",
      showTypewriter: false,
    },
    "schilderij-10": {
      text: "Woonstap 18-",
      showTypewriter: false,
    },
    "schilderij-11": {
      text: "Woonoplossingen",
      showTypewriter: false,
    },
    "schilderij-12": {
      text: "Hij weet niet hoe",
      showTypewriter: false,
    },
    "schilderij-13": {
      text: "Het is netwerken",
      showTypewriter: false,
    },
    "schilderij-14": {
      text: "Jongerenregisseur",
      showTypewriter: false,
    },
  };

  return strings[name];
}

function getDownloadsForObjectName(name) {
  const strings = {
    "flyer-1": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/16_Financieringsmodel.pdf`,
          name: "16_Financieringsmodel.pdf",
        },
      ],
    },
    "flyer-2": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/17_Factsheet_en_lessons_learned.pdf`,
          name: "17_Factsheet_en_lessons_learned.pdf",
        },
      ],
    },
    "flyer-3": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/18_Downloadable_Lokale_verordening_Sociaal_Domein.pdf`,
          name: "18_Downloadable_Lokale_verordering_Sociaal_Domein.pdf",
        },
      ],
    },
    "flyer-4": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/19_Morele_Standaard_in_het_sociaal_domein.pdf`,
          name: "19_Morele_Standaard_in_het_sociaal_domein.pdf",
        },
      ],
    },
    flipover: {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/15_Wat_is_de_Out_of_the_box-box.pdf`,
          name: "15_Wat_is_de_Out_of_the_box-box.pdf",
        },
      ],
    },
    banner: {
      downloads: [],
    },

    // Schilderijen
    "schilderij-1": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/1_Cluster_1_Leefgeld.png`,
          name: "1_Cluster_1_Leefgeld.png",
        },
      ],
    },
    "schilderij-2": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/2_Cluster_1_Briefadres.pdf`,
          name: "2_Cluster_1_Briefadres.pdf",
        },
      ],
    },
    "schilderij-3": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/3_Cluster_1_Prepaid_pinpas.pdf`,
          name: "3_Cluster_1_Prepaid_pinpas.pdf",
        },
      ],
    },
    "schilderij-4": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/4_Flexibiliteit_complexe_zorg_18-.pdf`,
          name: "4_Flexibiliteit_complexe_zorg_18-.pdf",
        },
      ],
    },
    "schilderij-5": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/5_Cluster_2_Overbruggingsregeling.pdf`,
          name: "5_Cluster_2_Overbruggingsregeling.pdf",
        },
      ],
    },
    "schilderij-6": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/6_Cluster_3_Integraal_werken_met_DBM.pdf`,
          name: "6_Cluster_3_Integraal_werken_met_DBM.pdf",
        },
      ],
    },
    "schilderij-7": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/7_Cluster_3_Impact_DBM.pdf`,
          name: "7_Cluster_3_Impact_DBM.pdf",
        },
      ],
    },
    "schilderij-8": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/8_Cluster_4_Doorbraakhypotheek.pdf`,
          name: "8_Cluster_4_Doorbraakhypotheek.pdf",
        },
      ],
    },
    "schilderij-9": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/9_ Cluster_4_Rent_for_Good.pdf`,
          name: "9_ Cluster_4_Rent_for_Good.pdf",
        },
      ],
    },
    "schilderij-10": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/10_Cluster_4_Woonstap.pdf`,
          name: "10_Cluster_4_Woonstap.pdf",
        },
      ],
    },
    "schilderij-11": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/11_A_Cluster_4_Businesscase_Wonen.pdf`,
          name: "11_A_Cluster_4_Businesscase_Wonen.pdf",
        },
        {
          url: `${process.env.REACT_APP_URL}/11_B-Casussen_met_Doorbraak_huisvesting-onderbouwing.pdf`,
          name: "11_B-Casussen_met_Doorbraak_huisvesting-onderbouwing.pdf",
        },
      ],
    },
    "schilderij-12": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/12_Cluster_5_gedragsproblematiek.pdf`,
          name: "12_Cluster_5_gedragsproblematiek.pdf",
        },
      ],
    },
    "schilderij-13": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/13_Cluster_6 Netwerk_betrekken.pdf`,
          name: "13_Cluster_6 Netwerk_betrekken.pdf",
        },
      ],
    },
    "schilderij-14": {
      downloads: [
        {
          url: `${process.env.REACT_APP_URL}/14_advies_Jongerenpanel_Jongerenregisseur.pdf`,
          name: "14_advies_Jongerenpanel_Jongerenregisseur.pdf",
        },
      ],
    },
    intro: {
      downloads: [],
    },
    logos: {
      downloads: [],
    },
  };

  return strings[name].downloads;
}

const globalState = proxy({
  popupMode: false,
  activeObject: "",
  camToSave: {},
  camera: null,
});

export {
  globalState as default,
  getTextForObjectName,
  getDownloadsForObjectName,
};
