import React, { useState, useMemo, useRef } from "react";
import { useGLTF, useDetectGPU, useCursor, Text } from "@react-three/drei";
import * as THREE from "three";
import { useSound, useTextures } from "./hooks";

import { useFrame, useThree } from "@react-three/fiber";
import { isMobile, isTablet, isSafari, isIOS } from "react-device-detect";

import * as TWEEN from "tween";
import globalState from "./globalState";
import gsap from "gsap";

// Three js objects
import Paintings from "./Paintings";
import Steps from "./Steps";
import Foundation from "./Foundation";
import Objects from "./Objects";
import Sound from "./Sound";
import Fence from "./Fence";
import MuseumKeeper from "./MuseumKeeper";
import ThreeA from "./ThreeA";
import ThreeB from "./ThreeB";
import MuseumDoor from "./MuseumDoor";

import GardenPath from "./GardenPath";

export default function Model(props) {
  const group = useRef();
  const beginRef = useRef();
  const firstUpdate = useRef(true);

  // GLTF
  const { nodes, materials } = useGLTF("./dbm-final.glb");

  const geom = useMemo(() => new THREE.PlaneBufferGeometry(85, 61), []);

  // Camera
  const camera = useThree((state) => state.camera);

  const GPUTier = useDetectGPU();

  // State
  const [active, setActive] = useState(false);
  const [tutorialMode, setTutorialMode] = useState(true);
  const [tweenRunning, setTweenRunning] = useState(false);

  useCursor(active);

  useFrame((state) => {
    TWEEN.update();
    if (camera.controls) camera.controls.update();
  });

  function onclick(event, ref, y = 7) {
    if (tweenRunning || !ref.current) return;

    const newPos = new THREE.Vector3();
    newPos.setFromMatrixPosition(ref.current.matrixWorld);
    newPos.y = y;
    camera.updateMatrixWorld();

    camera.controls.enabled = false;
    camera.controls.update();

    setTweenRunning(true);

    new TWEEN.Tween(camera.position)
      .to(
        {
          x: newPos.x,
          y: newPos.y,
          z: newPos.z,
        },
        2000
      )
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        updateCameraOrbit();
      })
      .onComplete(() => {
        camera.controls.enabled = true;
        camera.controls.update();
        updateCameraOrbit();
        camera.controls.minAzimuthAngle = -Infinity;
        camera.controls.maxAzimuthAngle = Infinity;
        setTweenRunning(false);

        globalState.activeObject = "";
        globalState.popupMode = false;
      })
      .start();
  }

  function updateCameraOrbit() {
    //Update OrbitControls target to a point just in front of the camera
    var forward = new THREE.Vector3();
    camera.getWorldDirection(forward);

    camera.controls.target.copy(camera.position).add(forward);
  }

  const config = [
    { ref: useRef(), objectName: "stap-1" },
    { ref: useRef(), objectName: "stap-2" },
    { ref: useRef(), objectName: "stap-3" },
    { ref: useRef(), objectName: "stap-4" },
    { ref: useRef(), objectName: "stap-5" },
    { ref: useRef(), objectName: "stap-6" },
    { ref: useRef(), objectName: "stap-7" },
    { ref: useRef(), objectName: "stap-8" },
    { ref: useRef(), objectName: "stap-9" },
    { ref: useRef(), objectName: "stap-10" },
    { ref: useRef(), objectName: "stap-11" },
    { ref: useRef(), objectName: "stap-12" },
    { ref: useRef(), objectName: "stap-13" },
    { ref: useRef(), objectName: "stap-14" },
    { ref: useRef(), objectName: "stap-15" },
    { ref: useRef(), objectName: "stap-16" },

    { ref: useRef(), objectName: "tuin-stap-1" },
    { ref: useRef(), objectName: "tuin-stap-2" },
    { ref: useRef(), objectName: "tuin-stap-3" },
    { ref: useRef(), objectName: "tuin-stap-4" },
    { ref: useRef(), objectName: "tuin-stap-5" },
    { ref: useRef(), objectName: "tuin-stap-6" },
    { ref: useRef(), objectName: "tuin-stap-7" },
    { ref: useRef(), objectName: "tuin-stap-8" },
    { ref: useRef(), objectName: "tuin-stap-9" },
    { ref: useRef(), objectName: "tuin-stap-10" },

    { ref: useRef(), objectName: "deurklink-1" },
  ];

  React.useEffect(() => {
    console.log("GPUTiert: ===>", GPUTier);

    const destination = config.find(
      ({ objectName }) => objectName === "tuin-stap-1"
    ).ref.current;

    if (destination && firstUpdate.current) {
      var position = destination.position.clone();
      console.log("position", position);
      camera.controls.enabled = false;

      gsap.timeline().to(camera.controls.target, {
        duration: 1,
        x: position.x,
        y: position.y,
        z: position.z, // maybe adding even more offset depending on your model
        onUpdate: function () {
          camera.controls.update();
        },
        onComplete: function () {
          camera.controls.maxDistance = 18;
          camera.controls.enabled = true;
          camera.controls.update();
        },
      });
    }
  }, [camera, GPUTier]);

  return (
    <group ref={group} {...props}>
      <Sound
        url={`${isIOS || isSafari ? "/museum-dbm.m4a" : "/museum-dbm.mp3"}`}
      />

      <Foundation nodes={nodes} materials={materials} />

      <Objects
        tutorialMode={tutorialMode}
        beginRef={beginRef}
        nodes={nodes}
        materials={materials}
      />

      <Paintings />

      <Steps
        onclick={(e, ref) => {
          setTutorialMode(false);
          onclick(e, ref);
        }}
        test={(e, ref) => {
          if (isMobile || isTablet) {
            setTutorialMode(false);
            onclick(e, ref);
          }
        }}
        setTutorialMode={setTutorialMode}
        config={config}
        setActive={setActive}
      />

      {/* Right fences */}
      <Fence scale={0.5} position={[-95, 1.8, 30.65]} />
      <Fence scale={0.5} position={[-123.3, 1.8, 30.65]} />
      <Fence scale={0.5} position={[-151.5, 1.8, 30.65]} />

      {/* Left fences */}
      <Fence scale={0.5} position={[-95, 1.8, -29.4]} />
      <Fence scale={0.5} position={[-123.3, 1.8, -29.4]} />
      <Fence scale={0.5} position={[-151.5, 1.8, -29.4]} />

      {/* Back fence */}
      <Fence
        scale={0.5}
        position={[-165.5, 1.8, -15]}
        rotation-x={Math.PI}
        rotation-y={Math.PI * -0.5}
        rotation-z={Math.PI}
      />
      <Fence
        scale={0.5}
        position={[-165.5, 1.8, 2]}
        rotation-x={Math.PI}
        rotation-y={Math.PI * -0.5}
        rotation-z={Math.PI}
      />

      {/* Bushes*/}
      <ThreeA scale={1} position={[-151.5, -1, -15]} />
      <ThreeA scale={1} position={[-125.5, -1, -14]} />

      <ThreeB scale={1} position={[-124.5, -1, 15]} />
      <ThreeB scale={1} position={[-100.5, -1, 14]} />

      {/* Stone Path */}
      <GardenPath
        config={config}
        setActive={setActive}
        onclick={(e, ref) => {
          onclick(e, ref, 3);
        }}
        test={(e, ref) => {
          if (isMobile || isTablet) {
            onclick(e, ref, 3);
          }
        }}
      />

      {/* Garden grass */}
      <mesh
        material-color="#9DBC3B"
        name="tuin"
        geometry={geom}
        castShadow
        receiveShadow
        position={[-125, -0.85, 1.2]}
        rotation-x={Math.PI * -0.5}
      />

      <Text
        position={[-83, 11, 0]}
        color="#fff"
        fontSize={2}
        maxWidth={200}
        lineHeight={1}
        letterSpacing={0.02}
        rotation-x={Math.PI}
        rotation-y={Math.PI * -0.5}
        rotation-z={Math.PI}
        textAlign={"center"}
        anchorX="center"
        anchorY="middle"
      >
        3D - Doorbraakmuseum
      </Text>

      <MuseumDoor
        beginRef={beginRef}
        position={[-83, -0.9, 20]}
        rotation-x={Math.PI}
        rotation-y={Math.PI * -0.5}
        rotation-z={Math.PI}
        config={config}
        setActive={setActive}
      />

      {/* Museum keeper */}
      <MuseumKeeper
        position={[-83, -0.9, 12]}
        rotation-x={Math.PI}
        rotation-y={Math.PI * -0.45}
        rotation-z={Math.PI}
      />
    </group>
  );
}

useGLTF.preload("./dbm-final.glb");
