import React from "react";

const Step = ({ position, myRef: ref, geom, mat, ...rest }) => (
  <mesh
    geometry={geom}
    material={mat}
    ref={ref}
    castShadow
    receiveShadow
    position={position}
    rotation-x={Math.PI * -0.5}
    {...rest}
  />
);

export default React.memo(Step);
